import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useRedirectTimeout = (duration = 5, to = '/') => {
  const router = useRouter()
  const [timeLeftBeforeRedirect, setTimeLeftBeforeRedirect] = useState(duration)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeftBeforeRedirect((prev) => {
        if (prev === 1) router.push(to)
        return prev - 1
      })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return { timeLeftBeforeRedirect }
}

export default useRedirectTimeout
