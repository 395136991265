import LogoLayout from '@/shared/Layouts/LogoLayout/LogoLayout'
import { useRouter } from 'next/router'
import ErrorIcon from '@/public/error.svg'
import Button from '@/shared/Button/Button'
import { handleSupportHelp } from '@/components/Auth/components/AuthPage/AuthConfirm/useAuthConfirm'
import useRedirectTimeout from '@/hooks/useRedirectTimeout'

export default function FourOhFour() {
  const router = useRouter()
  const { timeLeftBeforeRedirect } = useRedirectTimeout(5)

  return (
    <LogoLayout>
      <div
        className="flex-form flex-grow-1 flex-horizontal-centered"
        style={{ textAlign: 'center', padding: '0 40px' }}
      >
        <ErrorIcon />
        <h2 className="primary-header">Запрашиваемая вами страница не найдена</h2>
        <p className="text-14px">Возможно, ссылка устарела или была неправильно введена.</p>
        <p className="text-14px">
          Вы будете автоматически перенаправленны на главную страницу через{' '}
          <span className="text--primary">{timeLeftBeforeRedirect}с</span>
        </p>
      </div>
      <div className="form-footer">
        <Button theme="grey" onClick={() => handleSupportHelp(`Ошибка 'Страница не найдена'`)}>
          Написать в техподдержку
        </Button>
        <Button theme="primary" onClick={() => router.push('/')}>
          Вернуться на главную
        </Button>
      </div>
    </LogoLayout>
  )
}
